<template>
  <div class="menu">
    <el-menu
      :default-active="$route.meta.elmenu || $route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#f5f5f5"
      text-color="#444444"
      active-text-color="#444444"
      :unique-opened="true"
      :router="true"
    >
      <el-submenu index="1">
        <template slot="title">
          <span>个人中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/editpwd">密码修改</el-menu-item>
          <el-menu-item index="/address">我的地址</el-menu-item>
          <el-menu-item index="/footprint">我的足迹</el-menu-item>
          <!-- <el-menu-item index="/addacount">账号管理</el-menu-item> -->
          <el-menu-item index="/countlist" v-if="commonData.group_id == 1 || (userInfo.company_id == 57 && userInfo.group_id != 2)"
            >账号管理</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2" v-if="commonData.group_id != 2 && commonData.group_id != 3">
        <template slot="title">
          <span>合同中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            :index="
              userCenterData &&
              userCenterData.company_info &&
              userCenterData.company_info.status == 1
                ? '/certificationinfo'
                : '/certification'
            "
            >认证管理</el-menu-item
          >
          <el-menu-item index="/contract">合同管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <span>交易中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/favlist">我的收藏</el-menu-item>
          <el-menu-item index="/cart" v-if="userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id == 1)">我的购物车</el-menu-item>
          <el-menu-item index="/orderlist" v-if="userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id == 1)">我的订单</el-menu-item>
          <el-menu-item
              index="/evaluatelist"
              v-if="(userInfo.company_id != 61 && userInfo.company_id != 57) || (userInfo.company_id == 61 && userInfo.group_id == 1)">
            评价管理
          </el-menu-item>
          <el-menu-item index="/saleslist" v-if="userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id == 1)">售后订单</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <span>采购中心</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="1-1">账单管理</el-menu-item> -->
          <el-menu-item index="/purchase">采购列表</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <span>财务中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/financial">对账管理</el-menu-item>
          <el-menu-item index="/remitmoney">汇款管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6" v-if="commonData.group_id != 2 && commonData.group_id != 3">
        <template slot="title">
          <span>发票中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/invoice">我的发票</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="7">
        <template slot="title">
          <span>申请中心</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="/applysettlementrecord">申请对账</el-menu-item> -->
          <el-menu-item index="/applystatusrecord">额度状态</el-menu-item>
          <el-menu-item index="/applychangerecord">额度管理</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="8" v-if="commonData.group_id != 2 && commonData.group_id != 3">
        <template slot="title">
          <span>审批中心</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            index="/approval"
            v-if="this.$store.getters.commonData.group_id == 1"
            >审批管理</el-menu-item
          >
          <el-menu-item index="/applysettlement">申请对账审批</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu
        index="9"
        v-if="this.$store.getters.userInfo.company_id == 57"
      >
        <template slot="title">
          <span>报表中心</span>
        </template>
        <el-menu-item-group>
          <!-- <el-menu-item index="/report">商品情况统计表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=f399d224-99d6-423e-9596-d48146335e87&accessToken=2a7b63e964f2e542eac3a2cc0979678c&dd_orientation=auto"
              target="_blank">
              商品情况统计表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/orderStatistics">订单情况统计表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=35693a32-a27b-4581-88ed-5cbcd8559732&accessToken=cb4bd99c042a5cd045ef5442581d9eaf&dd_orientation=auto"
              target="_blank">
              订单情况统计表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/LimitationPerformance">交货时效统计表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=35ba2b4b-cb64-40c1-b3e8-66215fc8d4a8&accessToken=e758dc58b652d81d5aeb5d8981a34508&dd_orientation=auto"
              target="_blank">
              交货时效统计表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/aftersales">售后情况统计表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=09cc51df-d062-4757-a847-bf3eebaee138&accessToken=54fcceaf60363b0aabfdcf069d71a544&dd_orientation=auto"
              target="_blank"
            >
              售后情况统计表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/category">产品类别统计表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=f0c28ddd-df84-4af1-8efc-8146e68a1675&accessToken=fe227ae3b65213647ec4b883cc16dba9&dd_orientation=auto"
              target="_blank"
            >
              产品类别统计表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/purchasefx">账号统计分析表</el-menu-item> -->
          <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=721569af-ae77-466f-81d6-9969702ee604&accessToken=709d191b15d017f547a0a940cc6db380&dd_orientation=auto"
              target="_blank"
            >
              账号统计分析表
            </a>
          </el-menu-item>
          <!-- <el-menu-item index="/purchaseAnalysis">多维度采购统计</el-menu-item> -->
          <!-- <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=54306b77-035b-48c0-80d9-04ccccb2d828&accessToken=ff987b17b7f66e9739bdcd43796b40fb&dd_orientation=auto"
              target="_blank"
            >
              多维度采购统计
            </a>
          </el-menu-item> -->
          <!-- <el-menu-item index="/costing">部门统计分析表</el-menu-item> -->
          <!-- <el-menu-item>
            <a
              href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=95570260-a0dc-44ee-9c1b-8c80cf165168&accessToken=a9e953e39681ea29e0503f6ce4132660&dd_orientation=auto"
              target="_blank"
            >
              部门统计分析表
            </a>
          </el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>

      <el-submenu
        index="10"
        v-if="this.$store.getters.userInfo.company_id == 61&&this.$store.getters.userInfo.id==153"
      >
        <template slot="title">
          <span>专属商城</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/exclusive">专属商品</el-menu-item>
          <!-- <el-menu-item index="/approve" >商品审批流</el-menu-item> -->
        </el-menu-item-group>
      </el-submenu>
      <el-submenu
        index="11"
        v-if="this.$store.getters.userInfo.company_id == 57"
      >
        <template slot="title">
          <span>智能驾驶舱</span>
        </template>
        <el-menu-item-group>
       
          <el-menu-item>
            <a href="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=a09f93ab-8f23-436f-a517-2f199561b879&accessToken=548fd165680af2dd7eb2fb13a3d278f5&dd_orientation=auto" target="_blank">
              驾驶舱中心
            </a>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- 湘雅定制采购计划模块 20240103 owen  -->
      <el-submenu
        index="12"
        v-if="this.$store.getters.userInfo.company_id == 61&&this.$store.getters.userInfo.id==153" >
        <template slot="title">
          <span>采购计划</span>
        </template>
        <el-menu-item-group>       
            <el-menu-item index="/shoppingPlan">采购方案清单</el-menu-item>
            <el-menu-item index="/shoppingPlanList">采购计划列表</el-menu-item>      
        </el-menu-item-group>
      </el-submenu>

        <!-- 湘雅定制采购计划模块 20240103 owen  -->
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Cache from "@/utils/cache";
export default {
  name: "Sidebar",
  props: {},
  components: {},
  data() {
    return {
      userCenterData: {},
      userInfo: {}, //用户信息
    };
  },
  computed: {
    ...mapGetters(["commonData"]),
  },
  watch: {},
  methods: {
    handleOpen(current) {
      if (current == 2) {
        this.getUserCenter();
      }
    },
    handleClose() {},
    getUserCenter() {
      this.$api.getUserCenterApi().then((res) => {
        this.userCenterData = res.data;
      });
    },
  },
  created() {
    this.userInfo = Cache.get("userInfo")
  //  console.log("22："+JSON.stringify(this.$store.getters.userInfo));
    // console.log("33："+JSON.stringify( this.$store.getters.userInfo.company_id));
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.menu {
  overflow: hidden;
  width: 248px;

  /deep/ .el-menu {
    border: none;
    padding: 15px 0;

    > li {
      border-bottom: 1px solid #e0e0e0;

      &.is-opened {
        &.el-menu-item,
        .el-submenu__title {
          &::before {
            // background-image: url("~@/assets/icon/icon-minus.png");
            content: "\e8c5";
            font-family: "iconfont";
            font-size: 20px;
            // width: 18px;
            // height: 18px;
            margin-right: 10px;
            color: $-color-primary;
          }
        }
      }

      .el-menu--inline {
        padding: 0;
      }

      &.el-menu-item,
      .el-submenu__title {
        height: 70px;
        line-height: 70px;
        font-size: 19px;
        padding: 0 0 0 27px !important;
        display: flex;
        align-items: center;

        &:hover {
          background-color: transparent !important;
        }

        &::before {
          content: "\e8c8";
          font-family: "iconfont";
          color: $-color-primary;
          font-size: 20px;
          // width: 18px;
          // height: 18px;
          margin-right: 10px;
          // // background-image: url("~@/assets/icon/icon-add.png");
          // background-size: 100%;
          // background-position: center;
        }

        i {
          display: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .el-menu-item-group {
        padding-bottom: 20px;

        .el-menu-item-group__title {
          padding: 0;
        }

        ul {
          // padding-bottom: 20px;
          .el-menu-item {
            font-size: 16px;
            line-height: 30px;
            height: 30px;
            padding: 0 0 0 60px !important;

            &.is-active {
              color: $-color-primary !important;
            }

            &:hover {
              background-color: inherit !important;
              color: $-color-primary !important;
            }
          }
        }
      }
    }
  }
}
</style>
